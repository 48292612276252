.card-button-container {
  flex-direction: row;
  display: flex;
}

.card-button {
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  display: flex !important;
}

.card-button:hover {
  background-color: #f8f9fa;
}

.card-button > i {
  font-size: 23px;
}

/*# sourceMappingURL=apps-page.8d3f557f.css.map */
